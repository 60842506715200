.button {
  display: flex;
  width: 384px;
  padding: var(--Spacing-8, 8px) var(--Spacing-16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-8, 8px);

  border-radius: 6px;
  border: 1px solid #cbd5e1;
  background: var(--white-100, #fff);
}
